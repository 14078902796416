import React from 'react';

import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';
import { graphql } from 'gatsby';
import { Gallery } from '../components/Gallery';

const IndexPage = ({ data }) => {
  return (
    <Layout fullMenu subPage>
      <Seo
        description=''
        keywords=''
        title='Rodinné focení'
      />
      <article id='main'>
        <header id='family-photo' className='sub-page'>
          <StaticImage
            src='../assets/images/pic01.jpg' alt='A kitten'
            placeholder='blurred'
            objectFit='cover'
            className='static-image'
            objectPosition='50% 0%'
          />
          <div className='header-text'>
            <h1>Rodinné focení</h1>
            <p>Nechte fotografie vyprávět Váš příběh.</p>
          </div>
        </header>
        <section className='wrapper style5'>
          <div className='inner'>
            <h3>Rodinné focení</h3>
            <p> Okamžiky strávené s rodinou a krásu přírody považuji za to nejkrásnější, co může život nabídnout. Tak co
              to spojit dohromady?
              Příroda, do které tak rádi utíkáme z dnešní uspěchané doby, nám pomáhá relaxovat, cítit se uvolněně a
              štastně. Nechte se zachytit takoví jací jste.
              Fotografie zachycené v přirozeném prostředí budou vyprávět váš příběh, přiběh plný emocí a štestí.
            </p>

            <p>
              Nejvyšší čas doplnit střípky rodinného příběhu....
            </p>

            <hr />

            <div>
              <Gallery data={data} imageAlt="Rodinné focení Beskydy" />
            </div>

            <hr />
            <h4>Kde se nechat vyfotit?</h4>
            <p>
              Aby byl přiběh skutečně jen váš, domluvíme se na místě které máte rádi nebo spojené s příjemnými žážitky.
              Na místě které vás vystihuje a doplňuje.
            </p>

            <p>
              Pokud budete chtít, navrhnu vám místa vhodná pro focení v daném ročním období.
              Příroda je váš fotoateliér ve kterém se dá fotit celoročně a pokaždé nabídné nádherné rekvizity.
              Na jaře vonící sady plné květů, v létě rozkvetlé louky či zlátnoucí klasy obílí odrážející krásu
              zapadajícího sluníčka,
              na podzim karneval barev spadaného listí, v zimě lesknoucí se drahokamy ledových květu.
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export const pageQuery = graphql`
    query FamilyImages {
        allFile(
            filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "gallery/familly"}}
            sort: {order: DESC, fields: name}
        ) {
            edges {
                node {
                    childImageSharp {
                        thumb: gatsbyImageData(width: 276, placeholder: BLURRED)
                        full: gatsbyImageData(layout: FULL_WIDTH)
                    }
                    name
                }
            }
        }
    }

`;

export default IndexPage;

